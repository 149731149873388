
const { API } = require("../controllers/API");
const { formatSSN } = require("./Global-Functions");

const checkDuplicates = async (sectionOne, sectionTwo, listValue, token, setGettingData, i9Id, handleSubmit, location, hideModal)=>{

   
    console.log(sectionOne);
    console.log(sectionTwo);
    console.log(listValue);
    console.log(token);

    const response = await  fetch(API + '/auth/refresh-user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token}) // Body data, change as needed
    });

    const refreshedUser = await response.json();
 console.log(refreshedUser.user.companyId)

 const resp2 = await fetch(API + '/everify/authentication/check-duplicates', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ssn: sectionOne.socialSecurityNumber,clientId: refreshedUser.user.companyId }) // Body data, change as needed
    });
    const data2 = await resp2.json();
console.log(data2)
    if(data2.errors){
      const caseFields = {data:null}
      if(data2.errors[0].error_code === 'ATTRIBUTE_REQUIRED' && data2.errors[0].attribute_name === 'client_company_id' || data2.errors[0].error_code === 'INVALID_CLIENT_COMPANY_AUTHORIZATION'){
        window.alert('E-Verify is not yet set up for this account. For more details feel free to contact Support@I9HelpDesk.com ')

      } else {
        window.alert('Error: '+ data2.errors.map(i=> {return(i.message)}))

      }
     console.log(location)
      hideModal();
      return caseFields
    }
    if(data2.results[0]){
        
    if(data2.results[0].case_status === 'CLOSE_CASE_AND_RESUBMIT' || data2.results[0].case_status === 'CLOSED')
    {
        let case_field = null
        if(listValue === 'listA'){
            case_field = {
          client_company_id: Number(refreshedUser.user.companyId),
          ssn: sectionOne.socialSecurityNumber.includes('-') ? sectionOne.socialSecurityNumber : formatSSN(sectionOne.socialSecurityNumber), 
          ['document_a_type_code']: sectionTwo.listA.documentTitle,
          citizenship_status_code:sectionOne.citizenshipStatus,
            }
            const caseFields = await checkFields(case_field, refreshedUser, sectionOne, sectionTwo, setGettingData);

            // Log the caseFields for debugging
            console.log(caseFields);
        
            // Return the result of checkFields
            return caseFields;
                  
        } else {
            case_field = {
              client_company_id: Number(refreshedUser.user.companyId),
              ssn: sectionOne.socialSecurityNumber.includes('-') ? sectionOne.socialSecurityNumber : formatSSN(sectionOne.socialSecurityNumber), 
              ['document_b_type_code']: sectionTwo.listB.documentTitle,
              ['document_c_type_code']: sectionTwo.listC.documentTitle,
              citizenship_status_code: sectionOne.citizenshipStatus
              }
              const caseFields = await checkFields(case_field, refreshedUser, sectionOne, sectionTwo, setGettingData);

              // Log the caseFields for debugging
              console.log(caseFields);
          
              // Return the result of checkFields
              return caseFields;
                    
              
        }
   
      
    } else {
        console.log(data2.results[0])
    
    
        try {
          const response = await fetch(API+ '/everify/update-case-number', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: refreshedUser.user._id, i9Id, caseNumber: data2.results[0].case_number, location })
          });
      
          if (!response.ok) {
            throw new Error('Failed to update case number');
          }
       
          // const data = await response.json();
          handleSubmit(true);
          return data2.results[0]
      
        } catch (error) {
          console.error('Error updating case number:', error.message);
          // Handle error appropriately, such as displaying a message to the user
          throw error;
        }
    }
} else {
    let case_field = null
    if(listValue === 'listA'){
        case_field = {
      client_company_id: Number(refreshedUser.user.companyId),
      ssn: sectionOne.socialSecurityNumber.includes('-') ? sectionOne.socialSecurityNumber : formatSSN(sectionOne.socialSecurityNumber), 
      ['document_a_type_code']: sectionTwo.listA.documentTitle,
      citizenship_status_code:sectionOne.citizenshipStatus,
        }
        const caseFields = await checkFields(case_field, refreshedUser, sectionOne, sectionTwo, setGettingData);

    // Log the caseFields for debugging
    console.log(caseFields);

    // Return the result of checkFields
    return caseFields;
            

    } else {
        case_field = {
          client_company_id: Number(refreshedUser.user.companyId),
          ssn: sectionOne.socialSecurityNumber.includes('-') ? sectionOne.socialSecurityNumber : formatSSN(sectionOne.socialSecurityNumber), 
          ['document_b_type_code']: sectionTwo.listB.documentTitle,
          ['document_c_type_code']: sectionTwo.listC.documentTitle,
          citizenship_status_code: sectionOne.citizenshipStatus
          }
    
          const caseFields = await checkFields(case_field, refreshedUser, sectionOne, sectionTwo, setGettingData);

    // Log the caseFields for debugging
    console.log(caseFields);

    // Return the result of checkFields
    return caseFields;
          
    }
    
}
 



      
    
  }

  const checkFields = async (case_field, refreshedUser, sectionOne, sectionTwo, setGettingData) => {
    // Perform asynchronous fetch operation
    const resp = await fetch(API + '/everify/authentication/check-case-fields', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ case_fields: case_field })
    });

    // Parse response JSON
    const data = await resp.json();

    if(data.error){
      const errorMessage = data.errors.map(error => error.message).join(', ');
      window.alert(`Error: ${errorMessage}`);
      return data;
    }
    console.log(data);

    // Extract required field names
    const filter = data.case_fields.filter(o => o.required === true);
    const fieldNamesArray = filter.map(item => item.field_name);
    console.log(fieldNamesArray);

    // Construct case_fields object
   let case_fields = 
    {
      client_software_version : '0',
      ["client_company_id"] : case_field.client_company_id,
      ["first_name"]: sectionOne.firstName,
      ["last_name"]: sectionOne.lastName,
      ["ssn"]: case_field.ssn,
      ["date_of_birth"]: sectionOne.dateOfBirth.split('T')[0],
      ["date_of_hire"]: sectionTwo.firstDayOfEmployment.split('T')[0],
      ["citizenship_status_code"]: case_field.citizenship_status_code,
      ["case_creator_name"]:`${refreshedUser.user.firstName} ${refreshedUser.user.lastName}`,
      ["case_creator_phone_number"]:refreshedUser.user.phoneNumber,
      ["case_creator_email_address"]:refreshedUser.user.email,
      [fieldNamesArray.includes('document_a_type_code') ? 'document_a_type_code' : 'document_b_type_code'] : fieldNamesArray.includes('document_a_type_code') ? sectionTwo.listA.documentTitle : sectionTwo.listB.documentTitle,
      [sectionTwo.listB.documentTitle === "DRIVERS_LICENSE" ? "document_sub_type_code" : '']: sectionOne.listBDocumentCode === "DRIVERS_LICENSE" ? sectionOne.listBSubDocumentCode: '',
      [fieldNamesArray.includes('document_c_type_code') ? 'document_c_type_code': ''] :fieldNamesArray.includes('document_c_type_code') ? sectionTwo.listC.documentTitle : '',
      [fieldNamesArray.includes('document_bc_number') ? "document_bc_number": ''] : fieldNamesArray.includes('document_bc_number') ? sectionTwo.listB.documentNumber : '',
      [fieldNamesArray.includes('document_sub_type_code') ? "document_sub_type_code": ''] : fieldNamesArray.includes('document_sub_type_code') ? sectionOne.listBSubDocumentCode : '',
      [fieldNamesArray.includes('us_state_code') ? "us_state_code": ''] : fieldNamesArray.includes('us_state_code') ? sectionOne.listBSubDocumentStateCode : '',
      [fieldNamesArray.includes('duplicate_continue_reason') ? "duplicate_continue_reason": '']:fieldNamesArray.includes('duplicate_continue_reason') ? 'INCORRECT_INFORMATION_ENTERED':'',
      [fieldNamesArray.includes('alien_number') ? "alien_number": '']:fieldNamesArray.includes('alien_number') ? sectionOne.uscisANumber:'',
      [sectionOne.additionalInfoType === "uscisANumber" ? "alien_number": '']:sectionOne.additionalInfoType === "uscisANumber" ? sectionOne.additionalInfo :  '',
      [sectionOne.additionalInfoType === "uscisANumber" ? "alien_number": '']:sectionOne.additionalInfoType === "uscisANumber" ? sectionOne.additionalInfo :  '',
      [sectionOne.additionalInfoType === "i94AdmissionNumber" ? "i94_number": '']:sectionOne.additionalInfoType === "i94AdmissionNumber" ? sectionOne.additionalInfo :  '',
      [sectionOne.additionalInfoType === "foreignPassport" ? "foreign_passport_number": '']:sectionOne.additionalInfoType === "foreignPassport" ? sectionOne.additionalInfo :  '',
      [sectionOne.additionalInfoType === "foreignPassport" ? "country_code": '']:sectionOne.additionalInfoType === "foreignPassport" ? sectionOne.countryOfIssuance :  '',
      [fieldNamesArray.includes('foreign_passport_number') ? "foreign_passport_number": '']:fieldNamesArray.includes('foreign_passport_number') ? sectionTwo.listA.documentNumber:'',
      [fieldNamesArray.includes('foreign_passport_number') && fieldNamesArray.includes('expiration_date') ? "expiration_date": '']:fieldNamesArray.includes('expiration_date') ? sectionTwo.listA.expirationDate.split('T')[0]:'',
      [fieldNamesArray.includes('country_code') ? "country_code": '']:fieldNamesArray.includes('country_code') ? sectionOne.countryCode:'',
      [fieldNamesArray.includes('i766_number') ? 'i766_number': ''] :fieldNamesArray.includes('i766_number') ? sectionTwo.listA.documentNumber : '',
      [fieldNamesArray.includes('us_passport_number') ? 'us_passport_number': ''] :fieldNamesArray.includes('us_passport_number') ? sectionTwo.listA.documentNumber : '',
      [fieldNamesArray.includes('i551_number') ? 'i551_number': ''] :fieldNamesArray.includes('i551_number') ? sectionTwo.listA.documentNumber : '',
      [fieldNamesArray.includes('i94_number') ? 'i94_number': ''] :fieldNamesArray.includes('i94_number') ? sectionTwo.listA.documentNumber : '',
      [sectionOne.listType === 'listA' && fieldNamesArray.includes('expiration_date') ? "expiration_date": '']:fieldNamesArray.includes('expiration_date') ? sectionTwo.listA.expirationDate.split('T')[0]:'',
      [sectionTwo.listB.documentTitle === 'DRIVERS_LICENSE' ? "expiration_date": '']: sectionOne.listType === 'listBC' && sectionTwo.listB.documentTitle === 'DRIVERS_LICENSE' ? sectionTwo.listB.expirationDate.split('T')[0]:'',
      
    };
    Object.keys(case_fields).forEach(key => {
      if (case_fields[key] === "") {
        delete case_fields[key];
      }
    });

    console.log(case_fields);
    setGettingData(false)

    return case_fields;
};

const handleSubmitEverify = async (case_fields, token, i9Id, handleSubmit, loading, location) => {
  try {
    // Refresh user token
    const refreshResponse = await fetch(API + '/auth/refresh-user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token }) // Body data, change as needed
    });

    if (!refreshResponse.ok) {
      throw new Error('Failed to refresh user token');
    }

    const refreshedUser = await refreshResponse.json();

    // Create case with refreshed user
    const createCaseResponse = await fetch(API + '/everify/authentication/create-case', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ caseObject: case_fields, id: refreshedUser.user._id, i9Id: i9Id, location })
    });

    if (!createCaseResponse.ok) {
      throw new Error('Failed to create case');
    }

    const responseData = await createCaseResponse.json();

    console.log(responseData)
    if (!responseData.success) {
      // Handle errors if any
      if (responseData.errors && responseData.errors.length > 0) {
        const errorMessage = responseData.errors.map(error => error.message).join(', ');
        window.alert(`Error: ${errorMessage}`);
        loading()
      } else {
        window.alert('Unknown error occurred. Please try again later.');
      }
    } else {
      // If no errors, proceed with submit
      handleSubmit();
    }
  } catch (error) {
    console.error('Error:', error.message);
    // Handle any unexpected errors
    window.alert('An unexpected error occurred. Please try again later.');
  }
};

const retrievePhoto = async (caseNumber) => {
  console.log(caseNumber)
  try {
      // Make a POST request to the backend route to retrieve the photo
      const response = await fetch(API+ `/everify/authentication/retrieve_photo`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ caseNumber })
      });

      console.log(response)

      // Handle response
      if (!response.ok) {
          // If response is not ok, throw an error
          const errorMessage = await response.text();
          throw new Error(errorMessage || 'Failed to retrieve photo');
      }

      // Parse the response JSON data
      const photoData = await response.json();

      // Return the photo data
      return photoData;
  } catch (error) {
      // Handle errors
      console.error('Error retrieving photo:', error.message);
      throw error;
  }
};

const photoMatch = async (caseNumber, matchStatus) => {
  console.log(caseNumber)
  try {
      // Make a POST request to the backend route to retrieve the photo
      const response = await fetch(API+ `/everify/authentication/photo_match`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ caseNumber, matchStatus })
      });

      // Handle response
      if (!response.ok) {
          // If response is not ok, throw an error
          const errorMessage = await response.text();
          throw new Error(errorMessage || 'Failed to retrieve photo');
      }

      // Parse the response JSON data
      const photoData = await response.json();

      // Return the photo data
      return photoData;
  } catch (error) {
      // Handle errors
      console.error('Error retrieving photo:', error.message);
      throw error;
  }
};



  module.exports= {
    checkDuplicates:checkDuplicates,
    handleSubmitEverify:handleSubmitEverify,
    retrievePhoto:retrievePhoto,
    photoMatch:photoMatch,

  }