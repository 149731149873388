import { Box, Button, Divider, Grid, IconButton, ListItemText, Menu, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { NavBar } from "../../components/NavBar";
import { TitleBar } from "../../components/TitleBar";
import axios from "axios";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { API } from "../../controllers/API";
import { citizenshipStatusArray, fillPDF, formatDate, statesArray } from "../../utils/Global-Functions";
import TooltipButton from "../../components/TooltipButton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Close, EditOutlined, Save, Visibility } from "@mui/icons-material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import i9form from '../../assets/forms/i-9-section-one-two.pdf'
import supplementBform from '../../assets/forms/supplement-b.pdf';
import supplementBform2 from '../../assets/forms/supplement-b.pdf';

import { PDFFill } from "../../components/PDFFill";
import LoaderModal from "../../components/LoaderModal";
import { EverifyStatus } from "../../components/Everify/EverifyStatus";
import { StartEverifyModal } from "../../components/Everify/StartEverifyModal";
import { checkDuplicates, handleSubmitEverify } from "../../utils/Everify";
import { EverifyStart } from "../../components/Everify/EverifyStart";
import { FormControl } from "react-bootstrap";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
let hideDots = '\u2022'+'\u2022'+'\u2022'+'\u2022'+'\u2022'+'\u2022'+'\u2022'+'\u2022'+'\u2022';

export const I9Details = ()=>{
    let navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [editing, setEditing] = useState(false);
    const [dataUpdate, setDataUpdate] = useState(null);
    const [update, setUpdate] = useState(false);
    const [data, setData] = useState(null);
    const [sectionTwo, setSectionTwo] = useState(null);
    const [everifyData, setEverifyData] = useState({data:null});
    const {state} = useLocation();
    const [imgModal, setImgModal] = useState(false)
    const [img, setImg] = useState(false)
    const [loading, setLoading] = useState(true);
    const [additionalInfoType, setAdditionalInfoType] = useState('');
    const { a, b, c } = state;
    const [citizenshipStatus, setCitizenShipStatus] = useState('')
    const [sendingSubmitting, setSendingSubmitting] = useState(false)
    const [gettingData, setGettingData] = useState(false);
    const [openEverify, setOpenEverify] = useState(false);
    const token = Cookies.get('token');

    const handleOpenModal = (a)=>{
        setImg(a)
        setImgModal(true);
    }

    const handleCloseModal = (a)=>{
        setImg('')
        setImgModal(false);
    }

    const [showSSN, setShowSSN] = useState(false);

    const handleToggleSSN = () => {
      setShowSSN(!showSSN);
    };

    const [anchorQmEl, setAnchorQmEl] = useState(null);


    const handleQuickMenuOpen = (event) => {
      setAnchorQmEl(event.currentTarget);
    };
  
    // Function to close the menu
    const handleQuickMenuClose = () => {
      setAnchorQmEl(null);
    };
    
  
    const fieldsArray = ['firstName', 'lastName']; // Add more field names as needed
    useEffect(() => {
      // Fetch user data from the server when the component mounts or when the user comes back to the page
      const fetchUserData = async () => {
        try {
          // Check if the token cookie exists before making the request
          const token = Cookies.get('token');
          if (!token) {
            console.error('Token not found. Redirecting to login.');
            navigate('/login');
            return;
          }
  
          const response = await axios.post(API+ '/auth/refresh-user', { token });
          const refreshedUser = response.data.user;
  
          // Update the user data in the state
          setUser(refreshedUser);
          console.log(user)
        } catch (error) {
          console.error('Error fetching user data:', error.message);
          navigate('/login'); // Redirect to the login page in case of an error
        }
      };
  
      fetchUserData();
    }, [update]);

    useEffect(() => {
  
     

        const checkData = async ()=>{
          console.log(a, b, c)
          const resp = await axios.post(API+'/i9/check-form',{id: a, i9Id: b, location: c})

          const response = await axios.post(API+ '/auth/refresh-user', { token });
          const refreshedUser = response.data.user;
  
          // Update the user data in the state
          setUser(refreshedUser);
           console.log(resp.data)
         
          if(resp.data.notFound){
            console.log('error')
      
            // navigate('/errorPage'); 
  
        }
  
        if(resp.data.alreadySubmitted){
        setData(resp.data)
        setSectionTwo(resp.data.sectionTwo);
        if(resp.data.everify?.data){
          setEverifyData(resp.data.everify);

        }
        console.log(resp.data)
        if(resp.data.sectionOne.additionalInfoType === 'uscisANumber'){
          setAdditionalInfoType('USCIS A-Number')
       
        }

        if(resp.data.sectionOne.additionalInfoType === 'i94AdmissionNumber'){
          setAdditionalInfoType('Form I-94 Admission Number')
       
        }

        if(resp.data.sectionOne.additionalInfoType === 'foreignPassport'){
          setAdditionalInfoType('Foreign Passport Number and Country of Issuance')
       
        }


        if(resp.data.sectionOne.citizenshipStatus === 'US_CITIZEN'){
          setCitizenShipStatus('U.S. Citizen')
          setLoading(false)
        }

        if(resp.data.sectionOne.citizenshipStatus === 'NONCITIZEN'){
          setCitizenShipStatus('Noncitizen National of the United States')
          setLoading(false)
        }

        if(resp.data.sectionOne.citizenshipStatus === 'LAWFUL_PERMANENT_RESIDENT'){
          setCitizenShipStatus('Lawful Permanent Resident')
          setLoading(false)
        }

        if(resp.data.sectionOne.citizenshipStatus === 'NONCITIZEN_AUTHORIZED_TO_WORK'){
          setCitizenShipStatus('Noncitizen Authorized to Work')
          setLoading(false)
        }
        if(resp.data.sectionOne.citizenshipStatus === 'ALIEN_AUTHORIZED_TO_WORK'){
          setCitizenShipStatus('Alien Authorized to Work')
          setLoading(false)
        }
     
      }
        }
        checkData()
    
      }, [navigate, update]);

      const handleInputChange = (fieldName, value) => {
        setDataUpdate((prevData) => ({
          ...prevData,
          [fieldName]: value,
        }));
        console.log(data.sectionOne)
        console.log(dataUpdate)
      };

      const compareAndLogChanges = (obj1, obj2) => {
        let changeLog = '';
      
        const parsePropertyName = (property) => {
          return property
            .split('.')
            .map((segment) =>
              segment.replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space before capital letters
                     .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')  // Add space between consecutive capitals if followed by lowercase
                     .replace(/^./, (str) => str.toUpperCase()) // Capitalize first letter of each segment
            )
            .join(' ');
        };
      
        const compare = (key, value1, value2, path) => {
          const fullPath = path ? `${path}.${key}` : key;
          const readableProperty = parsePropertyName(fullPath);
      
          if (typeof value1 === 'object' && typeof value2 === 'object') {
            compareObjects(value1, value2, fullPath);
          } else if (value1 !== value2) {
            changeLog += `${readableProperty} was changed from "${value1}" to "${value2}".\n`;
          }
        };
      
        const compareObjects = (obj1, obj2, path = '') => {
          const keys1 = Object.keys(obj1);
          const keys2 = Object.keys(obj2);
      
          keys1.forEach((key) => {
            if (keys2.includes(key)) {
              compare(key, obj1[key], obj2[key], path);
            }
          });
        };
      
        compareObjects(obj1, obj2);
      
        console.log(changeLog);
        return changeLog;
      };

      const handleUpdate = async ()=>{
        console.log(c)
        setLoading(true)
     let reason = window.prompt('This cannot be undone and will be logged. Please give a reason for the edits:')

     if(reason) {
      
        const changeLog = compareAndLogChanges(data.sectionOne, dataUpdate)

        const response = await axios.post(API +'/i9/update-section-one', {
          id: a, 
          i9Id: b,
          formData: dataUpdate,
          changeLog:changeLog,
          reason:reason,
          location: c
        });
    
        // Check the response and handle accordingly
        if (response.data.success) {
          // Form data saved successfully
          console.log('Form data updated successfully!');
          setUpdate(!update)
          setLoading(false)
          // You can navigate to another page or perform additional actions here
        }


     } else {

     }

      }
      
      


      if(loading){
        return (<LoaderModal open={true}/>)
      } else {
        
    return (
        <Box sx={{ height: '100%', background: '#F4F4F4' }}>
            <NavBar/>
            <TitleBar
            onClick={handleQuickMenuOpen}
            To={-1}
            NavigationTitle="Back"
            Title={<Typography sx={{fontSize:24}}><b>Section 1 Employee Information and Attestation</b></Typography>}
            ButtonTitle={"View Documents"}
            ButtonIcon={<ArrowDropDownIcon/>}
            DisplayButton={sectionTwo?.submitted}
            Menu={
            <Menu
              
              id="profile-menu"
              anchorEl={anchorQmEl}
              open={Boolean(anchorQmEl)}
              onClose={handleQuickMenuClose} 
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
           
            >
              <MenuItem>
              <Link style={{display: sectionTwo?.submitted ? 'inline' : 'none', marginRight:2, textDecoration:'none'}} state={{sTwo: sectionTwo, sOne: data, id:a, i9Id:b, location:c, everifyData}} to='/i9s/section-two-details'> <ListItemText>
                Section Two
              </ListItemText>
              </Link>
             
              </MenuItem>
            <Divider sx={{display: data.sectionOne.supplementA[0].firstName !== '' ? 'block': 'none'}}/>
              <MenuItem sx={{display: data.sectionOne.supplementA[0].firstName !== '' ? 'block': 'none'}}>
              <Link style={{display: sectionTwo?.submitted ? 'block' : 'none', marginRight:2, textDecoration:'none'}} state={{sOne: data.sectionOne, id:a, i9Id:b, location: c}} to='/i9s/supplement-a'>
              <ListItemText>
                Supplement A
              </ListItemText>
              </Link>
              </MenuItem>
              <Divider/>
              <MenuItem>
              <Link style={{display: sectionTwo?.submitted ? 'inline' : 'none', marginRight:2, textDecoration:'none',}} state={{sOne: data.sectionOne, sTwo: sectionTwo, id:a, i9Id:b, location:c}} to='/i9s/supplement-b'>
              <ListItemText>
                Supplement B
              </ListItemText>
              </Link>
              </MenuItem>
             

            
              {(everifyData.data !== null && c !== 'inactive' && (
                <>
                 <Divider/>
                  <MenuItem>
              <Link style={{marginRight:2, textDecoration:'none',}} state={{sOne: data.sectionOne, sTwo: sectionTwo, id:a, i9Id:b, caseNumber: everifyData.data?.case_number}} to='/i9s/everify'>
              <ListItemText>
                E-Verify
              </ListItemText>
              </Link>
              </MenuItem>
              </>
              ))}
                {everifyData.data === null && c !== 'inactive' && user.clientId !== '' && (
                  <>
              <Divider/>
              
               <MenuItem onClick={()=>{handleQuickMenuClose(); setOpenEverify(true)}}>
              <ListItemText sx={{color:'#0c6dfd'}}>
               Start E-Verify
              </ListItemText>
              </MenuItem>
              
              </>
             )}
           
              <Divider/>

              
              <PDFFill formData={data} sectionTwo={sectionTwo} doc={i9form} supplementB={supplementBform} supB={supplementBform2}/>
            </Menu>}
            />

            <Box sx={{padding:5}}>
              <Box sx={{display: sectionTwo.submitted ? 'block': 'none'}}>
                {everifyData.data !== null  && c !== 'inactive' && user.clientId !== '' &&  (
                <Box >
                <EverifyStatus
                caseNumber={everifyData.data?.case_number}
                state={{sOne: data.sectionOne, sTwo: sectionTwo, id:a, i9Id:b, caseNumber: everifyData.data?.case_number, location: c}}
                />


                </Box>
                )}
                { everifyData.data === null  && c !== 'inactive' && user.clientId !== '' &&  (
                 <Box>
                 <EverifyStart
                 onClick={()=>setOpenEverify(true)}
                 />
                 </Box>
                )}
                 </Box>
              <Box sx={{marginBottom:2, textAlign:'end'}}>

              <Link style={{display: sectionTwo?.submitted ? 'none' : 'inline'}} state={{data: data.sectionOne, id:a, i9Id:b}} to='/forms/section-two'><Button variant="contained">Start Section Two</Button></Link> 
         
              </Box>
                <Paper sx={{padding:5}}>
                  <Box sx={{textAlign:'end'}}>
                  {!editing && everifyData.data === null && (<TooltipButton Title={<Box><Typography><b>Edit Section 1</b></Typography><Typography><b>Note:</b> Editing is only allowed before <br/>E-Verfiy has been started. All edit actions will be saved to the account log.<br/>You will be asked to give a reason for the edit.</Typography></Box>} Icon={<EditOutlined/>} onClick={()=>{setEditing(true); setDataUpdate(data.sectionOne)}}/> )}
                     {editing && (<><TooltipButton onClick={()=>{setEditing(false); setDataUpdate(null)}} Title={<Typography>Cancel Editing</Typography>} Icon={<Close/>}/>  <TooltipButton Title={<Box><Typography><b>Save Section 1 Edits</b></Typography><Typography><b>Note:</b> Editing is only allowed before <br/>E-Verfiy has been started. All edit actions will be saved to the account log.<br/>You will be asked to give a reason for the edit.</Typography></Box>} Icon={<Save/>} onClick={()=>{handleUpdate(); setEditing(false)}}/></>)}

                    </Box>
                  <Grid sx={{marginTop:2}} spacing={1} container>
                  <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Personal Information</b></Typography>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={4}>
              <Typography ><b>Last Name *</b></Typography>
              {!editing && (
              <Typography>{data.sectionOne.lastName}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.lastName}
                 onChange={(e) => handleInputChange('lastName', e.target.value)}
               />
              )}
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>First Name *</b></Typography>
            {!editing && (
              <Typography>{data.sectionOne.firstName}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.firstName}
                 onChange={(e) => handleInputChange('firstName', e.target.value)}
               />
              )}
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Middle Initial</b></Typography>
            {!editing && (
              <Typography>{data.sectionOne.middleInitial}</Typography>
              )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.middleInitial}
                 onChange={(e) => handleInputChange('middleInitial', e.target.value)}
               />
              )}
            </Grid>

            <Grid sx={{marginTop:2}}  item xs={12} sm={12}>
            <Typography ><b>Other Names Used</b></Typography>
            {!editing && (
            <Typography>{data.sectionOne.otherLastNames !== "" ? data.sectionOne.otherLastNames :'NA'}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 value={dataUpdate.otherLastNames}
                 onChange={(e) => handleInputChange('otherLastNames', e.target.value)}
               />
              )}
            
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={4}>
              <Typography ><b>Date of Birth *</b></Typography>
              {!editing && (
              <Typography>{formatDate(data.sectionOne.dateOfBirth)}</Typography>
            )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 type="date"
                 value={dataUpdate.dateOfBirth}
                 onChange={(e) => handleInputChange('dateOfBirth', e.target.value)}
               />
              )}
            </Grid>
            <Grid sx={{ marginTop: 2 }} item xs={12} sm={4}>
      <Typography>
        <b>SSN *</b>
      </Typography>
      {!editing && (
              <Typography>{showSSN ? data.sectionOne.socialSecurityNumber : hideDots} 
              <TooltipButton
              Title={'Show'}
              Icon={<Visibility/>}
              onClick={()=>handleToggleSSN()}
              /></Typography>            )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.socialSecurityNumber}
                 onChange={(e) => handleInputChange('socialSecurityNumber', e.target.value)}
               />
              )}
      
  

    </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Email Address</b></Typography>
            {!editing && (
                          <Typography>{data.sectionOne.emailAddress !== "" ? data.sectionOne.emailAddress :'NA'}</Typography>
                        )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.emailAddress}
                 onChange={(e) => handleInputChange('emailAddress', e.target.value)}
               />
              )}
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>Phone Number</b></Typography>
            {!editing && (
            <Typography>{data.sectionOne.telephoneNumber !== "" ? data.sectionOne.telephoneNumber : 'NA'}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.telephoneNumber}
                 onChange={(e) => handleInputChange('telephoneNumber', e.target.value)}
               />
              )}
            </Grid>
                  </Grid>
                  <hr/>

                  <Grid sx={{marginTop:2}} spacing={1} container>
                  <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Address</b></Typography>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={6}>
              <Typography ><b>Street Address *</b></Typography>
              {!editing && (
              <Typography>{data.sectionOne.address}</Typography>
            )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.address}
                 onChange={(e) => handleInputChange('address', e.target.value)}
               />
              )}
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={6}>
            <Typography ><b>Apt. Number *</b></Typography>
            {!editing && (
            <Typography>{data.sectionOne.aptNumber}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.aptNumber}
                 onChange={(e) => handleInputChange('aptNumber', e.target.value)}
               />
              )}
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>City</b></Typography>
            {!editing && (
            <Typography>{data.sectionOne.city}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.city}
                 onChange={(e) => handleInputChange('city', e.target.value)}
               />
              )}
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>State</b></Typography>

            {!editing && (
            <Typography>{statesArray[data.sectionOne.state]}</Typography>
          )}
              {editing && (
                 <Select
                 fullWidth
                 value={dataUpdate.state}  // Use `value` instead of `defaultValue`
                 sx={{ backgroundColor: 'white' }}
                 onChange={(e) => handleInputChange('state', e.target.value)}
               >
                 {statesArray.map((state, index) => (
                   <MenuItem key={index} value={index}>{state}</MenuItem>
                 ))}
               </Select>
              )}
            
            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={4}>
            <Typography ><b>ZIP</b></Typography>
            {!editing && (
            <Typography>{data.sectionOne.zipCode}</Typography>
          )}
              {editing && (
               <TextField
               sx={{backgroundColor:'white'}}
               required
                 fullWidth
                 
                 value={dataUpdate.zipCode}
                 onChange={(e) => handleInputChange('zipCode', e.target.value)}
               />
              )}
            </Grid>
            </Grid>
            <hr/>
            
            <Grid spacing={1} sx={{marginTop:2}} container>
                  <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Citizenship Status</b></Typography>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={12}>
              <Typography ><b>Check one of the following boxes to attest to your citizenship or immigration status (See page 2 and 3 of the instructions.): *</b></Typography>
             
              {!editing && (
              <Typography>{citizenshipStatus}</Typography>
            )}
              {editing && (
                <Select
                fullWidth
                value={dataUpdate.citizenshipStatus}  // Use `value` instead of `defaultValue`
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => handleInputChange('citizenshipStatus', e.target.value)}
              >
                {citizenshipStatusArray.map((status, index) => (
                  <MenuItem key={index} value={status.code}>{status.name}</MenuItem>
                ))}
              </Select>
              )}
            </Grid>

            {editing && dataUpdate.citizenshipStatus === 'NONCITIZEN_AUTHORIZED_TO_WORK'   && (
  <>
    <Grid item xs={12}>
      <FormControl fullWidth variant="outlined">
      <Typography sx={{marginTop:2}}><b>Select Type</b></Typography>
        <Select
        sx={{backgroundColor:'white'}}
          labelId="additional-info-type-label"
          id="additional-info-type"
          value={dataUpdate.additionalInfoType}
          onChange={(e) => handleInputChange('additionalInfoType', e.target.value)}
          
        >
          <MenuItem value="---">--Select Type---</MenuItem>
          <MenuItem value="uscisANumber">USCIS A-Number</MenuItem>
          <MenuItem value="i94AdmissionNumber">Form I-94 Admission Number</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid item xs={12}>
    <Typography sx={{marginTop:2}}><b>Enter the Number *</b></Typography>
      <TextField
      sx={{backgroundColor:'white'}}
        variant="outlined"
        fullWidth
        value={dataUpdate.additionalInfo}
        onChange={(e) => handleInputChange('additionalInfo', e.target.value)}
      />
    </Grid>
    {editing && dataUpdate.additionalInfoType === 'foreignPassport' && (
      <Grid item xs={12}>
        <Typography sx={{marginTop:2}}><b>Country of Issuance *</b></Typography>
        <TextField
         sx={{backgroundColor:'white'}}
          variant="outlined"
          fullWidth
          value={dataUpdate.countryOfIssuance}
          onChange={(e) => handleInputChange('countryOfIssuance', e.target.value)}
        />
      </Grid>
    )}
  
  </>
)}

{!editing && data.sectionOne.citizenshipStatus === 'NONCITIZEN_AUTHORIZED_TO_WORK'   && (
  <>
    <Grid item xs={12}>
      <FormControl fullWidth variant="outlined">
      <Typography sx={{marginTop:2}}><b>Type </b></Typography>
      <Typography>{data.sectionOne.additionalInfoType}</Typography>

      </FormControl>
    </Grid>
    <Grid item xs={12}>
    <Typography sx={{marginTop:2}}><b>{data.sectionOne.additionalInfoType} Number</b></Typography>
    <Typography>{data.sectionOne.additionalInfo}</Typography>
    </Grid>

  
  </>
)}

{editing && dataUpdate.citizenshipStatus === 'ALIEN_AUTHORIZED_TO_WORK'   && (
  <>
    <Grid item xs={6}>

      <Typography sx={{marginTop:2}}><b>Select Type</b></Typography>
        <Select
        sx={{backgroundColor:'white'}}
          labelId="additional-info-type-label"
          fullWidth
          id="additional-info-type"
          value={dataUpdate.additionalInfoType}
          onChange={(e) => handleInputChange('additionalInfoType', e.target.value)}
          
        >
          <MenuItem value="---">--Select Type---</MenuItem>
          <MenuItem value="uscisANumber">USCIS A-Number</MenuItem>
          <MenuItem value="i94AdmissionNumber">Form I-94 Admission Number</MenuItem>
        </Select>

    </Grid>
    <Grid item xs={6}>
    <Typography sx={{marginTop:2}}><b>Enter the Number *</b></Typography>
      <TextField
      sx={{backgroundColor:'white'}}
        variant="outlined"
        fullWidth
        value={dataUpdate.additionalInfo}
        onChange={(e) => handleInputChange('additionalInfo', e.target.value)}
      />
    </Grid>
  </>
)}

{!editing && data.sectionOne.citizenshipStatus === 'ALIEN_AUTHORIZED_TO_WORK'   && (
<>
<Grid item xs={6}>

      <Typography sx={{marginTop:2}}><b>Type </b></Typography>
      <Typography>{data.sectionOne.additionalInfoType ==='uscisANumber' ? 'USCIS or A-Number':'Form I-94 Admission Number'}</Typography>

    </Grid>
    <Grid item xs={6}>
    <Typography sx={{marginTop:2}}><b>{data.sectionOne.additionalInfoType ==='uscisANumber' ? 'USCIS or A-Number':'Form I-94 Admission Number'}</b></Typography>
    <Typography>{data.sectionOne.additionalInfo}</Typography>
    </Grid>
    </>
)}






{editing && dataUpdate.citizenshipStatus === 'LAWFUL_PERMANENT_RESIDENT'   && (
    <Grid item xs={12}>
   <Typography sx={{marginTop:2}}><b>USCIS or A-Number *</b></Typography>
      <TextField
        label="Enter USCIS or A-Number"
        fullWidth
        sx={{backgroundColor:'white'}}
        value={dataUpdate.uscisANumber}
        onChange={(e) => handleInputChange('uscisANumber', e.target.value)}
      />
    </Grid>
  )}
            
            </Grid>
            <hr/>
            <Grid sx={{marginTop:2}} container>
                  <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Signature</b></Typography>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={6}>
            <Typography ><b>Signature:</b></Typography>
                 <img style={{width:'100%'}} src={data.sectionOne.signature} alt="Base64 Image"/>

            </Grid>
            <Grid sx={{marginTop:2}}  item xs={12} sm={6}>
              <Typography ><b>Date:</b></Typography>
              <Typography>{formatDate(data.sectionOne.signatureDate)}</Typography>

            </Grid>
            </Grid>
            <hr/>
            <Grid sx={{marginTop:2}} container>
                  <Grid item xs={12}>
              <Typography sx={{fontSize:18}} variant="button"><b>Preparer or Translator</b></Typography>
            </Grid>
            <Grid sx={{marginTop:2}} item xs={12} sm={12}>
            <Typography ><b>Did you use a preparer or translator?</b></Typography>
            <Typography>{data.sectionOne.usedPreparer}</Typography>
 
            </Grid>
            <div style={{display: data.sectionOne.usedPreparer === 'yes' ? 'block':'none'}}>
            <Typography>See Supplement A</Typography>
            </div>

            </Grid>

            <Modal 
            open={imgModal}
            onClose={handleCloseModal}
            >
              <Box sx={style}>
              <img style={{width:'100%', height:'auto'}} src={img}/>
              </Box>
            </Modal>

            <StartEverifyModal
          
            onSubmit={async () =>{ 
              setGettingData(true)
              setEverifyData(await checkDuplicates(data.sectionOne, data.sectionTwo, data.sectionOne.listType, token, setGettingData, b, ()=>window.location.reload(), c,()=>{setOpenEverify(false); setEverifyData([]); setGettingData(false);}))}}
            onClose={()=>{setOpenEverify(false); setEverifyData({data:null}); setGettingData(false)}}
            gettingData={gettingData}
            initialData={everifyData}
            open={openEverify}
            onSubmitEverify={()=>{setSendingSubmitting(true); handleSubmitEverify(everifyData, token, b, ()=>window.location.reload(),()=>setSendingSubmitting(false), c); }}
            sendingSubmitting={sendingSubmitting}
            showSkip={true}
            />

                </Paper>
            </Box>
        </Box>
    )
}

}